import React from 'react'
import FooterThree, { FooterWrapper } from '../common/footer/FooterThree';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import SlpitOne from '../elements/split/SlpitOne';
import ServiceTwo from '../elements/service/ServiceTwo';
import TimelineTwo from '../elements/timeline/TimelineTwo';
import SEO from '../common/SEO';
import Meta from '../components/Meta';
import usePageInfo from '../common/hooks';
import { getImg } from '../services';

const StaffAugmentation = () => {
  const { pageInfo } = usePageInfo('StaffAugmentation', 'ihtkHaYwvYnAdwo1J0Pa');
  return (
    <>
      <Meta
        title="Meet Your Extended Team - Teknikos Staff Augmentation"
        description="Discover flexible and scalable staffing solutions designed to keep your projects on track, on time, and on budget with Teknikos."
        keywords="Staff Augmentation, Scalable Staffing Solutions, Flexible Staffing, Rapid Resource Procurement, Streamlined Onboarding, Resource Verification, Professional Qualifications, Technical Assessments, References & Compliance, Regulatory Requirements, Technical Interviews, Role Alignment, Personal Development, Resource Profiles, Short-term Resourcing, Long-term Resourcing, Flexible Resource Scaling, Offshore Development Teams, Specialized Resource Placement"
      />
      <main className="page-wrapper">
        <SEO title="staff augmentation"/>
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-850">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h2 className="title theme-gradient" dangerouslySetInnerHTML={{__html: pageInfo?.title}}></h2>
                  <p className="description">{pageInfo?.subTitle}</p>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img src={pageInfo?.bgImg ? getImg(pageInfo?.bgImg) : ''} alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.WereHereToSupport?.orangeTitle}
                  title = {pageInfo?.WereHereToSupport?.sectionTitle}
                  description = ""
                />
              </div>
            </div>
            <SlpitOne 
              title={pageInfo?.WereHereToSupport?.title}
              description={pageInfo?.WereHereToSupport?.text}
              btnText={pageInfo?.WereHereToSupport?.btnText}
              link="/contact"
              url={pageInfo?.WereHereToSupport?.img}
            />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Conditionally render the section based on pageInfo.teknikosResourceVerification.text */}
        {pageInfo?.teknikosResourceVerification?.text && (
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = {pageInfo?.teknikosResourceVerification?.orangeTitle}
                    title = {pageInfo?.teknikosResourceVerification?.title}
                    description = {pageInfo?.teknikosResourceVerification?.text}
                  />
                </div>
              </div>
              <div className="row mt--80">
                <TimelineTwo timelineData={pageInfo?.teknikosResourceVerification?.teknikosResourceVerificationList}  classVar="no-gradient"  />
              </div>
            </div>
          </div>
        )}
        {/* Start Service Area  */}
        {/* Conditionally render the section based on pageInfo.solutions.text */}
        {pageInfo?.solutions?.text && (
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = {pageInfo?.solutions?.orangeTitle}
                    title = {pageInfo?.solutions?.title}
                    description = {pageInfo?.solutions?.text}
                  />
                </div>
              </div>
              <ServiceTwo 
                grid
                cardStyle = "card-style-1"
                textAlign = "text-start"
                serviceList={pageInfo?.solutions?.solutionsList}
              />
            </div>
          </div>
        )}
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionThree} />
      </main>

    </>
  )
}

export default StaffAugmentation;