import React from 'react'
import FooterThree, { FooterWrapper } from '../common/footer/FooterThree';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import SectionTitleNoMaxWidth from '../elements/sectionTitle/SectionTitleNoMaxWidth';
import SlpitOne from '../elements/split/SlpitOne';
import ServiceTwo from '../elements/service/ServiceTwo';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';
import SEO from '../common/SEO';
import Meta from '../components/Meta';
import usePageInfo from '../common/hooks';
import { getImg } from '../services';

const SoftwareDevelopment = () => {
  const { pageInfo } = usePageInfo('SoftwareDevelopment', '72UDP0Hfd2hTDvnWyvQa');
  return (
    <>
      <Meta
        title="Software Services that Deliver Outcomes - Teknikos"
        description="Unlock innovation and efficiency with Teknikos' development services. Learn how we can help you achieve your software development goals."
        keywords="Software Development, Custom Software, User-friendly Applications, Agile Development, Innovation, Quality Software, AI Integration, Chatbots, Virtual Assistants, Emerging Technologies, Immersive Experiences, Digital Signage, DevOps, Development Services, UI/UX Design, Future-proofing, Digital Properties, Automation"
      />
      <main className="page-wrapper">
        <SEO title="software development" />
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-850">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h2 className="title titleSmall theme-gradient" dangerouslySetInnerHTML={{__html: pageInfo?.title}}></h2>
                  <p className="description">{pageInfo?.subTitle}</p>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img src={pageInfo?.bgImg ? getImg(pageInfo?.bgImg) : ''} alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.wereHereToDeliver?.orangeTitle}
                  title = {pageInfo?.wereHereToDeliver?.sectionTitle}
                  description = ""
                />
              </div>
            </div>
            <SlpitOne 
              title={pageInfo?.wereHereToDeliver?.title}
              description={pageInfo?.wereHereToDeliver?.text}
              btnText={pageInfo?.wereHereToDeliver?.btnText}
              link="/contact"
              url={pageInfo?.wereHereToDeliver?.img}
            />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitleNoMaxWidth
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.services?.orangeTitle}
                  title = {pageInfo?.services?.title}
                  description = {pageInfo?.services?.text}
                />
              </div>
            </div>
            <ServiceTwo 
              cardStyle = "card-style-1"
              textAlign = "text-start"
              serviceList={pageInfo?.services?.servicesList}
              grid
            />
          </div>
        </div>
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionThree} />
      </main>

    </>
  )
}

export default SoftwareDevelopment;